import React, { useEffect, useRef, useState } from 'react'
import { Todo } from '../model';
import {AiFillEdit, AiFillDelete} from 'react-icons/ai';
import {MdDone} from 'react-icons/md';
import './styles.css';
import { Draggable } from 'react-beautiful-dnd';
type Props={
  todo:Todo;
  todos:Todo[];
  setTodos:React.Dispatch<React.SetStateAction<Todo[]>>;
  index:number;
}
export const SingleTodo:React.FC<Props> = ({index,todo,todos,setTodos}) => {

  const [edit,setEdit] =useState<boolean>(false)
  const [editTodo, setEditTodo] =useState<string>(todo.todo)
  const handleDone = (id:number)=>{

    setTodos(todos.map((todo)=>todo.id===id?{
      ...todo,isDone:!todo.isDone
    }:todo))

  };
  const handleDelete =(id:number)=>{
    setTodos(todos.filter((todo)=>todo.id !==id));
  }
  const handleEdit =(e:React.FormEvent,id:number)=>{
    e.preventDefault();
    todos.map((todo)=>
      (todo.id===id? {...todo, todo:editTodo}:todo)
    );
    setEdit(false);

  }
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(()=>{
    inputRef.current?.focus();
  },[edit]);
  return (
    <Draggable draggableId={todo.id.toString()} index={index}>

      {
        (provided,snapshot)=>(
        <form 
        {...provided.draggableProps} 
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className={`single_todo ${snapshot.isDragging?'drag':''}`} 
        onSubmit={(e)=>handleEdit(e,todo.id)}>
          {
            edit?(
              <input ref={inputRef} value={editTodo}  onChange={(e)=>setEditTodo(e.target.value)} className='single_todo--text'/>
            ):(
            
                todo.isDone ? (<s className="single_todo--text">{todo.todo}</s>):(<span className="single_todo--text">{todo.todo}</span>)
             
            )
          }
            
            <div>
                <span className="icon" onClick={()=>{
                  if(!edit && !todo.isDone){
                    setEdit(!edit)
                  }
                }}>
                  <AiFillEdit />
                </span>
                <span className="icon"> 
                  <AiFillDelete onClick={()=>handleDelete(todo.id)} />
                </span>
                <span className="icon" onClick={()=>handleDone(todo.id)}>
                  <MdDone />
                </span>
            </div>
        </form>
        )
      }
    
    </Draggable>
   
  )
}
